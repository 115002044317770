import React, { useEffect, useState } from "react";
import NavBar from "./NavBar";
import { Link } from "react-router-dom";
import axios from "axios";
import Spinner from "./Spinner";
import Alert from "./Alert";
import { useUser } from '../context/UserContext';
import { useNavigate } from "react-router-dom";
import useRequireAuth from '../hooks/useRequireAuth';
import server from '../config/server';
import ProjectBeneficiaries from './ProjectBeneficiaries';
import DeleteBeneficiary from "./DeleteBeneficiary";

const Beneficiaries = () => {
  useRequireAuth();
  const { userData } = useUser();
  const navigate = useNavigate();
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [showModal, setShowModal] = useState(null);

  useEffect(() => {
    const fetchBeneficiaries = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(`${server.apiUrl}/getAllBeneficiaries`, {
          userId: userData.id,
          role: userData.role
        });

        if (response.data.success) {
          if (response.data.userBeneficiaries.length)
            setBeneficiaries(response.data.userBeneficiaries);
          else {
            setAlertType("warning");
            setAlertMessage("No beneficiaries found.");
          }
        } else {
          setAlertType("danger");
          setAlertMessage("Beneficiaries could not be retrieved.");
        }
      } catch (error) {
        setAlertType("danger");
        setAlertMessage("Beneficiaries could not be retrieved.");
      }
      setIsLoading(false);
    };

    fetchBeneficiaries();
  }, [userData]);

  const handleEditBeneficiary = (beneficiary) => {
    let editBeneficiaryUrl = `/beneficiaries/edit?id=${beneficiary.formId}`;
    navigate(editBeneficiaryUrl);
  };

  const handleOpenModal = (beneficiary) => {
    setSelectedBeneficiary(beneficiary);
    setShowModal(true);
  };

  const handleDeleteBeneficiary = (beneficiary) => {
    setBeneficiaries((prevBeneficiaries) =>
      prevBeneficiaries.filter((ben) => ben.formId !== beneficiary.formId)
    );
  };

  return (
    <div>
      <NavBar />
      <div className="container mt-4">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <h2>Beneficiaries</h2>
            <Link
              to={`/beneficiaries/add`}
              className="btn btn-outline-secondary mt-3"
            >
              Add Beneficiary
            </Link>
            {alertMessage ? (
              <Alert type={alertType} message={alertMessage} />
            ) : (
              <>
                <table className="table table-striped table-hover mt-4">
                  <thead>
                    <tr>
                      <th></th>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Verified</th>
                      <th>Contact No.</th>
                      <th>Occupation</th>
                      <th>Notes</th>
                      <th>Created By</th>
                      <th>Coordinator</th>
                      {userData && userData.role === "Admin" && (<th></th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {beneficiaries.map((beneficiary) => (
                      <tr key={beneficiary.formId}>
                        <td>
                          {/* <i className="bi bi-plus-square-fill bi-2x" style={{ "font-size": "20px" }} data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => handleOpenModal(beneficiary.id)}></i> */}
                          <i className="bi bi-plus-square-fill bi-2x" style={{ "font-size": "20px" }} onClick={() => handleOpenModal(beneficiary)}></i>
                        </td>
                        <td>{beneficiary.id}</td>
                        <td>{beneficiary.name}</td>
                        <td>
                          {beneficiary.isVerified === "true" ? (
                            <button className="btn btn-sm btn-success">
                              <i className="bi bi-check"></i>
                            </button>
                          ) : (
                            <button className="btn btn-sm btn-danger me-2">
                              <i className="bi bi-x"></i>
                            </button>
                          )}
                        </td>
                        <td>{beneficiary.contactNo}</td>
                        <td>{beneficiary.occupation}</td>
                        <td>{beneficiary.notes}</td>
                        <td>{beneficiary.createdByName}</td>
                        <td>{beneficiary.coordinatorName}</td>
                        <td>
                          <button
                            className="btn btn-sm btn-outline-success me-2"
                            onClick={() => handleEditBeneficiary(beneficiary)}
                          >
                            <i className="bi bi-pencil-square"></i>
                          </button>
                          {userData && userData.role === "Admin" && (
                            <DeleteBeneficiary
                              beneficiary={beneficiary}
                              onDelete={handleDeleteBeneficiary}
                            />
                          )}
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                  <ProjectBeneficiaries beneficiary={selectedBeneficiaryId} />
                </div> */}
                {showModal && (
                  <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    {/* <div className="modal-dialog" role="document">
                      <div className="modal-content"> */}
                    {/* Modal content */}
                    <ProjectBeneficiaries beneficiary={selectedBeneficiary} />
                  </div>
                  //   </div>
                  // </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div >
  );
};

export default Beneficiaries;